*{
  padding: 0;
  margin: 0px;
  box-sizing: border-box;
}
.w-60{
  width: 60%;
}
.w-20{
  width: 20%;
}
.w-40{
  width: 40%;
}
.profile-table-view{
  width: 32px;
  height: 32px;
  border-radius: 52px;
}
.prev-next-btn{
  border-radius: 10px;
  background: #6c757d;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  padding: 6px 9px;
  border: none;
}
.current-page-btn{
  border-radius: 10px;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  padding: 6px 16px;
  border: none;
  background: #0facd5;
}
.home-nav{
  width: 100%;
  padding: 19px 0px;
  /* background: #f4f2f2; */
  padding-bottom: 10px;
  border-top: 1px solid #bdbdbd;
}
.home-nav .home-nav-ul{
  list-style: none;
  float: right;
}
.bg-0facd5{
  background: #0facd5;
}
.border-bottom-2px{
  border-bottom: 2px solid #707070;
}
.radio-danger{
  accent-color: red;
  height: 20px;
  width: 20px;
}
.radio-success{
  accent-color: green;
  height: 20px;
  width: 20px;
}
.radio-white{
  accent-color: rgb(237, 235, 235);
  height: 20px;
  width: 20px;
}
.radio-yellow{
  accent-color: rgb(255, 255, 0);
  height: 20px;
  width: 20px;
}
.home-nav .home-nav-ul li{
  display: inline-block;
  cursor: pointer;
}
.home-nav .home-nav-ul li div{
  display: inline-block;
  background: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 999px;
  padding-top: 3px;
  /* margin-top: -4px; */
  box-shadow: 0 1px 5px rgba(0,0,0,.1), 0 1px 2px rgba(0,0,0,.2);
}
.home .hero-section{
  /* height: calc(100vh - 96px); */
  background: #2168AD;
}

.home .hero-section .trial-btn{
  padding: 9px 15px;
  background: #FF9900;
  color: #ffffff;
  font-weight: bold;
  border: none;
  margin: 0 auto;
  box-shadow: 0 1px 5px rgba(0,0,0,.1), 0 1px 2px rgba(0,0,0,.2);
}

.home .hero-section .dashboard-img{
  width: 80%;
}
.signup{
  height: 100vh;
  background: #EEEEEE;
}
.signup-topbar{
  background: #2168AD;
  height: 250px;
}
.signup .signup-form{
  margin: 0 auto;
  width: 56%;
  height: 435px;
  background-color: #ffffff;
  margin-top: 45px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0,0,0,.1), 0 1px 2px rgba(0,0,0,.2);
  padding: 26px 25px;
}
.signup .signup-btn{
  background: #2168AD;
  padding: 8px 19px;
  color: #fff;
  border: none;
}
.pointer{
  cursor: pointer;
}
.approve-btn{
  background: #0facd5;
  padding: 8px 19px;
  color: #fff;
  border: none;
  border-radius: 8px;
}
.login-page{
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
  text-align: center;
  border-color: #fdfbfb;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}
.login-page .logo{
  width: 400px;
  margin-top: 15vh;
}
.login-page .login-div{
  width: 480px;
  margin: 0 auto;
  /* background-color: antiquewhite; */
  margin-top: 7vh;
}
.login-page .login-div span, .form-check-label{
  font-family: "Roboto";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.align-start{
  text-align: start;
}
.form-checkbox-input{
  width: 24px!important;
  height: 24px!important;
}

/* input[type='radio'] {
  accent-color: #e7554b;
} */
.checkbox-options{
  appearance: auto!important;
  accent-color: #1D74B4;
}
.login-btn{
  border-radius: 5px;
  background: #0aaad2;
  font-family: "Roboto";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  /* text-align: left; */
  color: #ffffff;
  width: 100%;
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
}
.register-or-login{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #a19e9e;
  margin-top: 15px;
  
}

.register-or-login span{
  font-family: "Roboto";
  font-size: 18px!important;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #2362aa!important;
}




/* Navbar css starts */

.navbar-wrapper{
  position: fixed;
}

.navbar-c{
  width: 260px;
  height: 100vh;
  padding: 0 12px; 
  border: 1px solid #b8b8b8;  
  position: relative;
}
.navbar-c .nav-logo{
  margin-top: 22px;
}
.text1{
  font-family: "Roboto";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  letter-spacing: 1px;
  color: #707070;
  margin-top: 55px;
}
.navbar-ul{
  list-style: none;
  padding-left: 0px;
}
.navbar-ul li{
  padding: 8px 9px;
  cursor: pointer;
  margin-bottom: 6px;
}
.navbar-ul li svg{
  margin-top: -4px;
  fill:#707070;
  stroke:#707070;
}
.navbar-ul li span{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  margin-left: 20px;
}
.navbar-ul .active-li{
  border-radius: 10px;
  background: #0facd5;
}
.navbar-ul .active-li span{
  color: #ffffff;
}
.navbar-ul .active-li svg{
  fill: #ffffff;
  stroke: #ffffff;
}
.user-li{
  border-radius: 10px;
  background: #f7f8fc;
  padding: 10px 12px;
  position: absolute;
  width: calc(100% - 24px);
  bottom: 14px;
}
.user-li p{
  font-family: "Roboto";
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.content-div{
  width: calc(100% - 260px);
  min-height: 100vh;
  /* background-color: #0aaad2; */
  margin-left: 260px;
}


/* Navbar css ends */






/* Topbar css starts  */

.topbar{
  width: 100%;
  height: 85px;
  background-color: #ffffff;
  text-align: end;
}
.notification-icon{
  margin-top: 17.5px;
  margin-right: 10%;

}


/* Topbar css ends  */






/* customer dashboard css starts   */

.customer-dashboard{
  width: 100%;
  background: #F7F8FC;
  padding: 33px 40px;
  min-height: calc(100% - 85px);
}
.text2{
  font-family: "Roboto";
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.text3{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  margin-top: 7px;
}

.dashboard-bar-ul{
  list-style: none;
  padding-left: 0px;
  float: right;
}
.dashboard-bar-ul li{
  display: inline-block;
  margin: 0 5px;
}
.text4{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #7c7f84;
}
.datepickerr{
  background: #ffffff;
  border: none;
  padding: 10px 12px;
  border-radius: 10px;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  /* text-align: right; */
  color: #707070;
  padding-left: 50px;
  width: 180px;
}
.datepickerr:focus{
  outline: none;
}
.p-relative{
  position: relative;
}
.date-picker-icon{
  /* margin-left: -80px; */
  position: absolute;
  left: 16px;
  top: 8px;
}
.filter-btn{
  font-family: "Roboto";
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  border-radius: 10px;
  background: #0facd5;
  border: none;
  padding: 9px 25px;
}

.table-card{
  border-radius: 15px;
  background: #ffffff;
  width: 100%;
  padding: 15px 25px;
  padding-bottom: 25px;
}

.mt-30{
  margin-top: 30px;
}
.working-th{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #2362aa;
}
.performancediv{
  background-color: #EAEDF9;
  width: 260px;
  height: 19px;
  border-radius: 50px;
}
.sc-csKJRI{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #b8b8b8;
}
.sc-eDLKEg{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.performnce-value{
  background-color: #2362aa;
  height: 19px;
  border-radius: 50px;
  /* width: 40%; */

}
.text5{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
}

.sc-blmCWO{
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #707070;
}

.break-th{
  color: #e38100;
}
.performancediv-break{
  background: #e38100;
}

.text6{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #11aa00;
}
.text7{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ff0000;
}

.users-list-select{
  width: 120px!important;
  border: none!important;
}
.text8{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #7c7f84;
}

.cursor-pointer{
  cursor: pointer;
}

.table-card2{
  /* max-height: 630px; */
  overflow-y: auto;
  padding: 30px 30px;
}
.screen-img{
  width: 100%;
  height: 200px;
  background: #b8b8b8;
  text-align: center;
}
.screen-img p{
  padding-top: 90px;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #7c7f84;
}
.text9{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #707070;
}

.overflow-hidden{
  overflow: hidden;
}

.dashboard-card{
  border-radius: 15px;
  background: #0facd5;
  width: 100%;
  padding: 15px 20px;
  padding-bottom: 25px;
  min-height: 135px;
}

.dashboard-card h4{
  font-size: 16px;
}
.dashboard-card h1{
  font-size: 22px;
}





/* customer dashboard css ends  */

.bg-0facd5{
  background: #0facd5!important;
}




/* employee profie page css starts  */

.text10{
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #707070;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 9px;
}

.text11{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.text12{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.clr-11aa00{
  color: #11aa00;
}



/* employee profie page css ends  */






/* dashboard css starts */
/* dashboard css ends */






/* personal comapnay page const starts  */


.personal-page{
  width: 100%;
  background: #F7F8FC;
  padding: 33px 40px;
  min-height: calc(100% - 85px);
}
.text13{
  font-family: "Roboto";
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.invite-personnel-btn{
  font-family: "Roboto";
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  background: #0facd5;
  padding: 12px 16px;
  border: none;
  margin-left: 25px;
}
.py-12{
  padding-top: 12px!important;
  padding-bottom: 12px!important;
  border-radius: 10px!important;
}
.searh-icon{
  position: absolute;
  top: 11px;
  right: 13px;
  height: 25px;
  width: 25px;
}
.sc-blmCWO{
  font-family: "Roboto"!important;
  font-size: 20px!important;
  font-weight: 500!important;
  font-style: normal!important;
  text-align: left!important;
  color: #707070!important;
}
.back-btn{
  border-color: #0facd5;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  padding: 8px 30px;
  background: #ffffff;
  font-family: "Roboto";
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #0facd5;
}
.edit-btn{
  border-color: #0facd5;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  padding: 8px 30px;
  background: #0facd5;
  font-family: "Roboto";
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
}





/* personal comapnay page const ends  */






/* swith button css  */

.switch-btn-div input{
  /* background: #FAFAFA; */
  background: #F7F8FC;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 28px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.toggle-station {
  height: 30px;
  width: 60px;
  border-radius: 46px;
  display: block;
  position: relative;
  margin: 0;
  /* background-color: gainsboro; */
  /* background-color: #CCCCCC; */
  background-color: #F7F8FC;
  transition: all .2s ease;
  margin-right: 8px;
}

.toggle-station:checked {
  background: #11AA00;
}

.toggle-station:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 1px 2px rgba(44,44,44,.2);
  transition: all .2s cubic-bezier(.5,.1,.75,1.35);
}

.toggle-station:checked:after {
  left: 11px;
  transform: translatex(20px);
}


/* input[type='radio'] {
  accent-color: #e7554b;
}
.checkbox-options{
  appearance: auto!important;
  margin-right: 7px;
  accent-color: #e7554b;

} */

.custom-form-select{
  border-radius: 10px!important;
  background: #f7f8fc!important;
  border: none!important;
  margin-top: 9px;
}

.text14{
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #707070;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
}

.genrate-pass-btn{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #7c7f84;
  border-radius: 10px;
  background: #f5f8fe;
  border: none;
  padding: 6px 21px;
}
.eye-icon{
  position: absolute;
  top: 16px;
  left: 185px;
}
.bg-f7f8fc{
  background: #f7f8fc!important;
}
.text15{
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #707070;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}
.text16{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.text17{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.w-35{
  width: 35%;
}
.basic-multi-select{
  width: 310px;
}
.basic-multi-select2 {
  width: 100%;
}
.basic-multi-select .css-13cymwt-control{
  background-color: #f7f8fc!important;
}
.bg-0facd5{
  background: #0facd5!important;
}



.p-relative2{
  position: relative;
}
.cross-btn{
  /* position: absolute; */
  /* top: 5px; */
  /* right: 15px; */
  /* display: inline-block; */
  padding: 2px 10px;
  border-radius: 500px;
  cursor: pointer;
  outline: none;
  border: none;
  float: right;
}
.cursor-pointer{
  cursor: pointer!important;
}

.task-list-ul li{
  height: 40px;
}


.pointer{
  cursor: pointer;
}

.session-table{
  width: 100%;
}
.session-table tbody tr{
  height: 48px;
  border-bottom: 1px solid rgba(0,0,0,.12);
}
.session-table tbody tr td{
  padding-top: 4px;
  padding-bottom: 4px;
}
.remove-border-bottom{
  border-bottom: none!important;
}
.green-row{
 background-color: rgba(0, 255, 0, 0.12)!important; 
}
.yellow-row{
  background-color: rgba(255, 255, 0, 0.12)!important;
}
.red-row{
  background-color: rgba(255, 0, 0, 0.12)!important;
}
.white-radio-div, .green-radio-div, .yellow-radio-div, .red-radio-div{
  width: 28px;
  height: 28px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  margin: 0 4px;
  border-radius: 4px;
}
.white-radio-div:hover, .green-radio-div:hover, .yellow-radio-div:hover, .red-radio-div:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.bg-blue{
  background-color: blue!important;
  color: #fff;
}
.white-radio-div{
  background-color: #fff;
}
.green-radio-div{
  background-color: green;
}
.yellow-radio-div{
  background-color: yellow;
}
.red-radio-div{
  background-color: red;
}

.label-profile{
  width: 24px;
  height: 24px;
  border-radius: 122px;
}


/* Screenshots models popup css starts */
.w-40{
  width: 40%;
}
.w-355{
  width: 35%;
}

.ss-model-div{
  position: relative;
}
.modal-dialog{
  margin-top: 12vh!important;
}
.ss-model-div .model-cross-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.ss-model-div .model-cross-icon path:first-child{
  fill: #fff!important;
}
.ss-model-div .carousel-control-next-icon, .ss-model-div .carousel-control-prev-icon{
  background-size: 45px 52px;
  background-color: #0FACD5;
  padding: 1px;
  border-radius: 999px;
  width: 55px;
  height: 55px;
}


/* Screenshots models popup css ends */





.report-detail-page{
  background-color: #F7F8FC;
  min-height: 87vh;
  padding: 35px 40px;  
}
.report-detail-page .heading{
  font-family: "Roboto";
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.report-detail-page .heading span{
  background: #0FACD5;
  padding: 5px 10px;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  margin-left: 17px;
}
.report-detail-page .back-btn-red{
  width: 51px;
  height: 21px;
  border-radius: 10px;
  background: #ff1616;
  font-family: "Roboto";
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  border: none;  
}
.report-detail-page .top-div{
  border-color: #707070;
  border-width: 1px;
  border-style: solid;
  border-radius: 15px;
  background: #f2fcff;
  padding: 30px 30px;
}
.report-detail-page .top-div h2{
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #707070;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 12px;
}
.report-detail-page .top-div .label{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.report-detail-page .top-div .value{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #707070;
}

.report-detail-page .bottom-div {
  border-color: #707070;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  background: #f9f9f9;
  padding: 30px 30px;
}
.report-detail-page .bottom-div table{
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #707070;
  width: 100%;
}
.report-detail-page .bottom-div table thead tr td{
  color: #b8b8b8;
}
.report-detail-page .bottom-div table tr {
  border-bottom: 1px solid #e1e1e1;
}
.report-detail-page .bottom-div table tr td{
  padding: 11px 0px!important;
}
.report-detail-page .bottom-div table tr td b{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.report-detail-page .bottom-div table tr td .grn-status{
  border-radius: 5px;
  background: #11aa00;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  padding: 5px 9px;
  min-width: 70px;
  
}
.report-detail-page .bottom-div table tr td .blue-status{
  background: #0facd5;
}