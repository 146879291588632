/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.invoice-header{
  border-bottom: 3px solid #303778;
}
.clr-303778{
  color: #303778;
}
.pdf-table1{
  width: 100%;
}
.pdf-table1 tr{
  background-color: rgb(237, 237, 237);
  border-bottom: 2px solid #f5f5f5;
}
.pdf-table1 tr td, .pdf-table1 tr th{
  height: 40px;
  padding: 4px 10px;
}
.w-60px{
  width: 60px;
}
.w-35{
  width: 35%;
}
.w-65{
  width: 65%;
}
.w-100px{
  width: 100px;
}
.bg-303778{
  background-color: #303778;
  color: #fff;
}
.pdf-table2{
  width: 100%;
}
.pdf-table2 tr th{
  height: 40px;
  padding: 4px 3px;
}
.pdf-table2 .bb-rgb-237{
  border-bottom: 2px solid rgb(237, 237, 237);
}
.pdf-table2 .bb-303778{
  border-bottom: 2px solid #303778;
}