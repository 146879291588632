@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-thumbnail.css');

.gallery-item {
    margin: 5px;
}
.custom-lightgallery {
    max-width: 80% !important;
    max-height: 80% !important;
    width: auto !important;
    height: auto !important;
    margin: auto;
  }
  
  /* .custom-lightgallery .lg-inner {
    max-width: 100% !important;
    max-height: 100% !important;
  }
  
  .custom-lightgallery .lg-item img {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: contain;
  } */